.edit-info .avater-box {
    width: 140px;
    margin: 0 auto;
    height: 140px;
    position: relative;
    display: inline-block;
    background: #ffffff;
	border: 1px solid #eaeff5;
    -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
    box-shadow: 0 2px 10px 0 #d8dde6;
	border-radius: 50%;
    padding: 10px;
}
.upload-btn-wrapper {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    bottom: -3px;
    left: 0;
}

.edit-info .btn {
    border: 1px solid #ff3a72;
    color: #ff3a72;
    background-color:#ffffff;
    padding: 6px 16px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}